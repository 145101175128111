import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import OptionsList from './OptionsList'; // Import the OptionsList component
import RegisterForm from './RegisterForm'; // Import the RegisterForm component
import PayPalButtonWrapper from './PaypalButtonWrapper'; // Import the PayPalButtonWrapper component

const ScannedPage = () => {
    const [showCustomMessage, setShowCustomMessage] = useState(false);
    const [customMessage, setCustomMessage] = useState('');
    const [useCases, setUseCases] = useState({});
    const [options, setOptions] = useState([]);
    const [selectedUseCase, setSelectedUseCase] = useState('');
    const [loading, setLoading] = useState(true);
    const [isActive, setIsActive] = useState(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [paymentRequired, setPaymentRequired] = useState(false);
    const [error, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const { id } = useParams();

    useEffect(() => {
        const fetchQRCodeStatus = async () => {
            try {
                const response = await axios.get(`/api/qrcode/${id}`);
                console.log("QR code status response:", response.data);
                setIsActive(response.data.active);
                if (response.data.active) {
                    console.log("Setting options:", response.data.options);
                    setOptions(response.data.options || []);
                } else {
                    const optionsResponse = await axios.get('/api/options');
                    console.log("Options response:", optionsResponse.data);
                    const useCaseKeys = Object.keys(optionsResponse.data);
                    const defaultUseCase = useCaseKeys[useCaseKeys.length - 1];
                    setUseCases(optionsResponse.data);
                    setOptions(optionsResponse.data[defaultUseCase] || []);
                    setSelectedUseCase(defaultUseCase);
                }
            } catch (error) {
                console.error("Error fetching QR code status:", error);
                if (error.response && error.response.status === 404) {
                    setPaymentRequired(true);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchQRCodeStatus();
    }, [id]);

    const handleUseCaseChange = (e) => {
        const selectedUseCase = e.target.value;
        setSelectedUseCase(selectedUseCase);
        setOptions(useCases[selectedUseCase]);
    };

    const handleButtonClick = (message) => {
        if (isActive) {
            setSelectedOption(message);
        }
    };

    const handleCustomMessageSubmit = (e) => {
        e.preventDefault();
        alert(`Custom message sent: ${customMessage}`);
        setCustomMessage('');
        setShowCustomMessage(false);
    };

    const handleRegisterSubmit = async (fullPhoneNumber) => {
        setError('');
        try {
            const registerResponse = await axios.post('/api/register', {
                email,
                phone: fullPhoneNumber,
                qrcode_id: id,
                name,
                use_case: selectedUseCase,
            });

            if (registerResponse.status === 200) {
                const response = await axios.get(`/api/qrcode/${id}`);
                setIsActive(response.data.active);
                if (response.data.options) {
                    console.log("Setting options after registration:", response.data.options);
                    setOptions(response.data.options);
                }
            }
        } catch (error) {
            setError(error.response?.data || 'There was an error registering the QR code.');
            console.error("There was an error registering the QR code!", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const notifyResponse = await axios.post('/api/notify', {
                qrcode_id: id,
                options: selectedOption,
            });

            if (notifyResponse.status === 200) {
                alert('Notification sent successfully');
            }
        } catch (error) {
            console.error('There was an error sending the notification!', error);
        }
    };

    const handlePaymentSuccess = async (details) => {
        try {
            const paymentResponse = await axios.post('/api/payment-success', {
                qrcode_id: id,
                payment_details: details
            });
            console.log("Payment success response:", paymentResponse.data);

            const qrCodeResponse = await axios.get(`/api/qrcode/${id}`);
            console.log("QR code status after payment:", qrCodeResponse.data);
            setIsActive(qrCodeResponse.data.active);
            setPaymentRequired(false);
            if (qrCodeResponse.data.options) {
                console.log("Setting options after payment:", qrCodeResponse.data.options);
                setOptions(qrCodeResponse.data.options);
            }
        } catch (error) {
            console.error('Error processing payment success:', error);
        }
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "10.00", // Set your price here
                    },
                    custom_id: id, // Add QR code ID here
                },
            ],
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(handlePaymentSuccess);
    };

    const paypalOptions = {
        "client-id": "ASaQl6nHiX9nhAuwrXZIk3txFD8IesPNkpxcdz9X6FShHcYDz3Cigu0riJrAso8pCzkBm2NEJrNrFA5k",
        "enable-funding": "venmo",
        currency: "USD",
        intent: "capture",
        components: "buttons,funding-eligibility"
    };

    console.log("Current options state:", options);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-xl font-semibold">Loading...</div>
            </div>
        );
    }

    return (
        <main className="flex-grow container mx-auto px-4 py-8">
            {paymentRequired ? (
                <div className="mt-8 text-center">
                    <p className="text-red-500 font-bold mb-4">Payment is required to activate this QR code.</p>
                    <div className="flex justify-center">
                        <div style={{ width: '300px' }}>
                            <PayPalScriptProvider options={paypalOptions}>
                                <PayPalButtonWrapper
                                    createOrder={createOrder}
                                    onApprove={onApprove}
                                />
                            </PayPalScriptProvider>
                        </div>
                    </div>
                </div>
            ) : !isActive ? (
                <RegisterForm
                    email={email}
                    phone={phone}
                    setPhone={setPhone}
                    name={name}
                    useCases={Object.keys(useCases)}
                    selectedUseCase={selectedUseCase}
                    options={options}
                    handleUseCaseChange={handleUseCaseChange}
                    handleRegisterSubmit={handleRegisterSubmit}
                    setEmail={setEmail}
                    setName={setName}
                    error={error}
                />
            ) : (
                <form onSubmit={handleSubmit}>
                    <OptionsList
                        options={options}
                        handleButtonClick={handleButtonClick}
                        showCustomMessage={showCustomMessage}
                        customMessage={customMessage}
                        handleCustomMessageSubmit={handleCustomMessageSubmit}
                        setCustomMessage={setCustomMessage}
                        setShowCustomMessage={setShowCustomMessage}
                    />
                    {selectedOption && (
                        <div className="mt-8 text-center">
                            <p className="text-lg">Selected Option: {selectedOption}</p>
                            <button
                                type="submit"
                                className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            )}
        </main>
    );
};

export default ScannedPage;
