import React from "react";

const ContactComponent = () => {
    return (
        <div className="flex items-center justify-center p-8 bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-3xl font-bold mb-4 text-center">Contact Us</h1>
                <div className="mb-4">
                    <h2 className="text-2xl font-semibold">Contact Information</h2>
                    <p><strong>Phone:</strong> +91.90505.63712</p>
                    <p><strong>Email:</strong> jsfarmsandorganics [at] gmail [dot] com</p>
                    <p><strong>Operating Address:</strong> <br />
                        31C, Lakhmirwala,<br />
                        Jind, Haryana,<br />
                        India - 126102
                    </p>
                </div>
                <div className="mb-4">
                    <h2 className="text-2xl font-semibold">Services/Products</h2>
                    <p><strong>Product:</strong> GoID</p>
                    <p><strong>Price:</strong> Rs 100</p>
                </div>
            </div>
        </div>
    );
}

export default ContactComponent;
