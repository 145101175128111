import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import React from "react";

const PayPalButtonWrapper = ({ createOrder, onApprove }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
        <>
            {isPending ? <div className="spinner"></div> : null}
            <PayPalButtons
                style={{
                    layout: 'vertical',
                    shape: 'rect',
                    color: 'blue'
                }}
                createOrder={createOrder}
                onApprove={onApprove}
            />
        </>
    );
};

export default PayPalButtonWrapper