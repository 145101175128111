import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode';
import axios from 'axios';
import { QrCode, MessageSquare, Shield, Car, Backpack, Dog, Home } from 'lucide-react';
import StockImage from './images/600.jpg';

const LandingPage = () => {
    const [qrCodeDownloaded, setQrCodeDownloaded] = useState(false);
    const navigate = useNavigate();
    const qrCodeId = uuidv4();

    const handleDownloadQRCode = async () => {
        try {
            const qrCodeUrl = `https://goid.realityapps.live/qrcode/${qrCodeId}`;
            const qrCodeDataUrl = await QRCode.toDataURL(qrCodeUrl, { width: 800, height: 800 });

            const link = document.createElement('a');
            link.href = qrCodeDataUrl;
            link.download = `GoID_${qrCodeId}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setQrCodeDownloaded(true);
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    const handleSetupGoID = async () => {
        try {
            const generateLinkResponse = await axios.post('/api/generate-link', { qrcode_id: qrCodeId });
            const { link } = generateLinkResponse.data;
            window.location.href = link;
        } catch (error) {
            console.error('Error generating link:', error);
        }
    };

    const useCases = [
        { icon: Car, title: "Vehicle Communication", description: "Place on your car for parking-related messages" },
        { icon: Backpack, title: "Lost & Found", description: "Attach to bags or valuables for easy return" },
        { icon: Dog, title: "Pet Identification", description: "Help lost pets return home safely" },
        { icon: Home, title: "Door Messages", description: "Leave notes for visitors or delivery services" },
    ];

    return (
        <div>
            <section className="py-20 bg-gray-50">
                <div className="container mx-auto px-4 flex items-center">
                    <div className="w-1/2 pr-12">
                        <h1 className="text-5xl font-bold mb-6 text-gray-900">Go Contactless with GoID</h1>
                        <p className="text-xl mb-8 text-gray-600">Enable safe, instant communication without sharing personal information</p>
                        <div className="flex space-x-4">
                            <button
                                onClick={handleDownloadQRCode}
                                className="bg-black text-white px-6 py-2 rounded-lg text-lg font-semibold hover:bg-gray-800 transition duration-300"
                            >
                                Download GoID
                            </button>
                            <button
                                onClick={handleSetupGoID}
                                disabled={!qrCodeDownloaded}
                                className={`px-6 py-2 rounded-lg text-lg font-semibold transition duration-300 ${qrCodeDownloaded ? 'bg-black hover:bg-gray-800 text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
                            >
                                Setup Your GoID
                            </button>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <img src={StockImage} alt="GoID in action" className="rounded-lg shadow-md" />
                    </div>
                </div>
            </section>

            {/* How It Works Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">How GoID Works</h2>
                    <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12">
                        <div className="text-center">
                            <div className="bg-gray-100 rounded-full p-6 mb-4 inline-block">
                                <QrCode size={40} className="text-gray-800" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2 text-gray-900">Generate GoID</h3>
                            <p className="text-gray-600">Create your unique GoID QR code</p>
                        </div>
                        <div className="text-4xl text-gray-300 hidden md:block">→</div>
                        <div className="text-center">
                            <div className="bg-gray-100 rounded-full p-6 mb-4 inline-block">
                                <MessageSquare size={40} className="text-gray-800" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2 text-gray-900">Place GoID</h3>
                            <p className="text-gray-600">Display your GoID where it's needed</p>
                        </div>
                        <div className="text-4xl text-gray-300 hidden md:block">→</div>
                        <div className="text-center">
                            <div className="bg-gray-100 rounded-full p-6 mb-4 inline-block">
                                <Shield size={40} className="text-gray-800" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2 text-gray-900">Secure Communication</h3>
                            <p className="text-gray-600">Receive messages without sharing personal info</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Use Cases Section */}
            <section className="py-20 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">GoID in Action</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                        {useCases.map((useCase, index) => (
                            <div key={index} className="bg-white p-8 rounded-lg shadow-sm">
                                <div className="flex items-center mb-4">
                                    <useCase.icon size={24} className="text-gray-800 mr-3" />
                                    <h3 className="text-xl font-semibold text-gray-900">{useCase.title}</h3>
                                </div>
                                <p className="text-gray-600">{useCase.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LandingPage;
