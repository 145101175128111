import React from 'react';
import { AlertTriangle, Dog, Car, Bus, Truck, DoorClosed, ShieldCheck, Search, Check, AlertCircle, DoorOpen, Cat, Rabbit, MessageSquare } from 'lucide-react';

const iconMap = {
    "car": Car,
    "bus": Bus,
    "truck": Truck,
    "dog": Dog,
    "cat": Cat,
    "rabbit": Rabbit,
    "door-closed": DoorClosed,
    "shield-check": ShieldCheck,
    "search": Search,
    "check": Check,
    "alert-triangle": AlertTriangle,
    "alert-circle": AlertCircle,
    "door-open": DoorOpen,
    "message-square": MessageSquare,
};

const OptionsList = ({ options, handleButtonClick, showCustomMessage, customMessage, handleCustomMessageSubmit, setCustomMessage }) => {
    return (
        <div className="grid grid-cols-2 gap-4 max-w-2xl mx-auto">
            {options.map((option) => {
                const Icon = iconMap[option.icon_type] || AlertTriangle;
                return (
                    <button
                        key={option.name}
                        type="button"
                        onClick={() => handleButtonClick(option.name)}
                        className="text-white p-6 rounded-lg flex flex-col items-center justify-center hover:opacity-75 transition duration-300"
                        style={{ backgroundColor: option.background_color }}
                    >
                        <Icon size={48} className="mb-2" />
                        <span className="text-lg font-semibold text-center">{option.name}</span>
                    </button>
                );
            })}
            {showCustomMessage && (
                <form onSubmit={handleCustomMessageSubmit} className="mt-8 max-w-2xl mx-auto">
                    <div className="flex">
                        <input
                            type="text"
                            value={customMessage}
                            onChange={(e) => setCustomMessage(e.target.value)}
                            placeholder="Enter your custom message"
                            className="flex-grow px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-6 py-2 rounded-r-lg hover:bg-blue-600 transition duration-300"
                        >
                            Send
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default OptionsList;
