import './App.css';
import React from "react";

import LandingPage from "./LandingPage";
import HeaderComponent from "./Header";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FooterComponent from "./Footer";
import ContactComponent from "./contact";
import SetupComponent from "./Setup";
import PrivacyComponent from "./Privacy";

function App() {
  return (
      <div className="min-h-screen flex flex-col bg-white text-gray-800">
      <Router>
          <HeaderComponent />
          <Routes>
              <Route path="/" exact element={<LandingPage />} />
              <Route path="/contact" element={<ContactComponent/>} />
              <Route path="/qrcode/:id" element={<SetupComponent/>} />
              <Route path="/privacy" element={<PrivacyComponent/>} />
          </Routes>
          <FooterComponent />
      </Router>

      </div>
  );
}

export default App;
