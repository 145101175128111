import React from "react";
import { Link } from 'react-router-dom';

const HeaderComponent = () => {
    return (
        <header className="py-6 border-b border-gray-200">
            <div className="container mx-auto px-4 flex justify-between items-center">
                <div className="text-2xl font-bold text-gray-900">GoID</div>
                <nav>
                    <ul className="flex space-x-8">
                        <li><Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link></li>
                        <li><Link to="/contact" className="text-gray-600 hover:text-gray-900">Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default HeaderComponent;