import React from "react";
import { Link } from 'react-router-dom';

const FooterComponent = () => {
    return (
    <footer className="bg-gray-100 py-12 mt-auto">
        <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                <div>
                    <h4 className="font-semibold mb-4 text-gray-900">Quick Links</h4>
                    <ul className="space-y-2">
                        <li><Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link></li>
                        <li><Link to="/contact" className="text-gray-600 hover:text-gray-900">Contact</Link></li>
                    </ul>
                </div>
                <div>
                    <h4 className="font-semibold mb-4 text-gray-900">Legal</h4>
                    <ul className="space-y-2">
                        <li><a href="/terms" className="text-gray-600 hover:text-gray-900">Terms of Service</a></li>
                        <li><a href="/privacy-policy" className="text-gray-600 hover:text-gray-900">Privacy Policy</a></li>
                        <li><a href="/return" className="text-gray-600 hover:text-gray-900">Cancellation and Returns</a></li>
                    </ul>
                </div>
            </div>
            <div className="mt-12 text-center text-gray-600">
                <p>&copy; 2024 GoID. All rights reserved.</p>
            </div>
        </div>
    </footer>
    );
}

export default FooterComponent;