import React, { useState } from 'react';
import { AlertTriangle, Dog, Car, Bus, Truck, DoorClosed, ShieldCheck, Search, Check, AlertCircle, DoorOpen, Cat, Rabbit, MessageSquare } from 'lucide-react';
import countryCodes from './countryCodes';

const iconMap = {
    "car": Car,
    "bus": Bus,
    "truck": Truck,
    "dog": Dog,
    "cat": Cat,
    "rabbit": Rabbit,
    "door-closed": DoorClosed,
    "shield-check": ShieldCheck,
    "search": Search,
    "check": Check,
    "alert-triangle": AlertTriangle,
    "alert-circle": AlertCircle,
    "door-open": DoorOpen,
    "message-square": MessageSquare,
};

const RegisterForm = ({
                          email,
                          phone,
                          setPhone,
                          name,
                          useCases,
                          selectedUseCase,
                          options,
                          handleUseCaseChange,
                          handleRegisterSubmit,
                          setEmail,
                          setName,
                          error,
                      }) => {
    const [selectedCountry, setSelectedCountry] = useState('+1'); // Default to United States

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fullPhoneNumber = `${selectedCountry}${phone}`;
        handleRegisterSubmit(fullPhoneNumber);
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Phone</label>
                <div className="flex">
                    <select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="shadow appearance-none border rounded-l w-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    >
                        {countryCodes.map((country) => (
                            <option key={country.code} value={country.code}>
                                {country.name} ({country.code})
                            </option>
                        ))}
                    </select>
                    <input
                        type="tel"
                        value={phone}
                        onChange={handlePhoneChange}
                        className="shadow appearance-none border rounded-r w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">What this QR Code is for - name it</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Use Case</label>
                <select
                    value={selectedUseCase}
                    onChange={handleUseCaseChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                >
                    {useCases.map((useCase) => (
                        <option key={useCase} value={useCase}>
                            {useCase}
                        </option>
                    ))}
                </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
                {options.map((option) => {
                    const Icon = iconMap[option.icon_type] || AlertTriangle;
                    return (
                        <button
                            key={option.name}
                            type="button"
                            className="text-white p-6 rounded-lg flex flex-col items-center justify-center hover:opacity-75 transition duration-300"
                            style={{ backgroundColor: option.background_color }}
                        >
                            <Icon size={48} className="mb-2" />
                            <span className="text-lg font-semibold text-center">{option.name}</span>
                        </button>
                    );
                })}
            </div>
            <div className="mt-4">
                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                    Register
                </button>
            </div>
        </form>
    );
};

export default RegisterForm;
